import Vue from 'vue'
import Vuex from 'vuex'
import auth from './modules/auth'
import records from './modules/records'
import orders from './modules/orders'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    auth,
    records,
    orders
  },  
  state: {
    loading: false,
    disabledSidebar: false,
  },
  getters: {
    isLoading: state => state.loading,
    disabledSidebar: state => state.disabledSidebar
  },  
  mutations: {
    setLoading (state, isLoading) {
      state.loading = isLoading
    },      
    setDisabledSidebar (state, disabledSidebar) {
      state.disabledSidebar = disabledSidebar
    }      
  },
  actions: {
    setLoading({commit}, isLoading) {
      return new Promise((resolve) => {
        commit('setLoading', isLoading)
        resolve()
      })
    },     
    setDisabledSidebar({commit}, disabledSidebar) {
      return new Promise((resolve) => {
        commit('setDisabledSidebar', disabledSidebar)
        resolve()
      })
    }     
  }
})
